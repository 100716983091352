import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'marketing',
      component: () => import('./Main.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./About.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('./Signup.vue')
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import('./Pricing2.vue')
    },
    {
      path: '/pricing-secret-8602',
      name: 'pricing',
      component: () => import('./Pricing2.vue')
    },
    {
      path: '/case-study-new-show',
      name: 'new-case-study',
      component: () => import('./CaseStudyNew.vue')
    },
    {
      path: '/request-a-demo',
      name: 'demo',
      component: () => import('./Demo.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
export default router
